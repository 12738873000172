<template>
  <div class="matchPage">
    <div class="patchContent" v-if="show == true">
      <div class="banner">
        <div class="finishImg">
          <img src="../../img/da2.png" alt="" />
        </div>
      </div>
      <p style="
          font-size: 15px;
          font-weight: 400;
          padding: 15px 57px;
          font-family: PingFangSC-Regular, PingFang SC;
        "></p>
      <div class="buttons"></div>
    </div>
    <div class="patchContent" v-else>
      <div class="banner">
        <div class="loadingImg">
          <img src="../../img/da2.png" alt="" />
        </div>
        <p class="arktitle">系统正在进行智能匹配 请稍后</p>
        <p style="font-size: 15px; margin-top: 40px">
          已匹配到
          <countTo :startVal="startVal" :endVal="endVal" :duration="6000"></countTo>个产品
        </p>
      </div>
    </div>
    <div class="footer">
      <p>
        <i class="iconfont iconyingyongbeifen"></i>系统全程严格保证企业数据安全
      </p>
    </div>
  </div>
</template>
<script>
  import url from "@/service/url-config.js";
  import countTo from "vue-count-to";
  export default {
    components: {
      countTo
    },
    data() {
      return {
        startVal: 0,
        endVal: 0,
        show: false,
        timer: "",
        count: 30,
        turn: 0,
        name: "",
        loanPredictionId: this.$route.query.loanPredictionId ||'',// 10380,
      };
    },
    created() {

    },
    mounted() {
      this.afterAuth();
    },
    methods: {
      // 匹配产品个数
      async afterAuth() {
        const {
          code,
        } = await this.$http.get(url.afterAuth + this.loanPredictionId + '&productState=' + 1);
        if(code==200){
          this.show = true;
          this.$router.replace({
            path:'/pipeiChanPin',query:{
              loanPredictionId:this.loanPredictionId
            },
          })
        }else{
          const timer = setInterval(() => {
            this.count--
            this.afterAuth()
            if( this.count<1)clearInterval(timer)
          }, 1000);
        }
      },
    },
    beforeDestroy() {
      clearTimeout(this.timer);
    },
  };
</script>
<style lang="less" scoped>
  .matchPage {
    background-color: #0084ff;
    height: calc(100vh);

    .patchContent {
      color: #fff;
      min-height: calc(100vh - 202px);
      box-sizing: border-box;
      padding-top: 60px;
      text-align: center;

      .banner {
        padding: 40px 77px;
        margin-top: 24px;
      }

      .finishImg {
        width: 81px;
        height: 64px;
        margin: auto;
        background: url("../../img/da1.png") no-repeat center;
        background-size: 100% 100%;

        img {
          width: 26px;
          margin-top: 40px;
        }
      }

      .matchTitle {
        font-size: 22px;
        font-weight: 600;
      }

      .loadingImg {
        width: 80px;
        height: 63px;
        background-image: url("../../img/da1.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: center;

        img {
          width: 26px;
          animation: spin 3s linear infinite;
        }
      }

      .arktitle {
        animation: blink 2s linear infinite;
        font-size: 22px;
        color: #fff;
      }

      // 文字闪烁动画
      @keyframes blink {
        0% {
          opacity: 1;
        }

        50% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      // 旋转动画
      @keyframes spin {
        from {
          transform: rotate(0deg);
        }

        to {
          transform: rotate(360deg);
          transition: all 5s;
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin-top: 49px;

      .van-button {
        border: none;
        color: #fff;
        width: 140px;
        height: 40px;
        background: #2e99ff;
        border-radius: 2px;
        font-size: 15px;
      }
    }

    .footer {
      height: 60px;
      text-align: center;
      font-size: 12px;
      color: #fff;
    }
  }
</style>